export const sumByCategory = (array, sumField, categoryField) => {
  let outcome = {};

  array.forEach((row) => {
    const category = row[categoryField];
    let sum = row[sumField];

    sum = !isNaN(sum) ? sum : 0; // checking if wrong format

    if (outcome[category]) {
      outcome[category] += sum;
    } else {
      outcome[category] = sum;
    }
  });

  return outcome;
};

export const groupByPeriod = (array, period, dateField) => {
  // grouping data by period (based on dateField)
  // possible period values: "day", "month", "year"

  const groupedData = array.reduce((acc, item) => {
    let key = "";
    const itemDate = new Date(item[dateField]);

    switch (period) {
      case "day":
        key = itemDate.toLocaleString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        break;
      case "month":
        key = itemDate.toLocaleString("default", {
          year: "numeric",
          month: "long",
        });
        break;
      case "year":
        key = itemDate.toLocaleString("default", { year: "numeric" });
        break;
      default:
        key = "unspecified";
        console.log("incorrect period entry");
    }

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(item);
    return acc;
  }, {});

  return groupedData;
};

// Transform the grouped data into a structure suitable for Recharts Stacked BarChart
export const groupByPeriodCategory = (
  data,
  period,
  dateField,
  sumField,
  categoryField
) => {
  //const groupedData = groupedByMonth(data);
  const groupedData = groupByPeriod(data, period, dateField);
  const allCategories = [...new Set(data.map((item) => item[categoryField]))];

  const chartData = Object.keys(groupedData).map((key) => {
    const periodicData = groupedData[key];

    const entry = { name: key };
    allCategories.forEach((category) => {
      entry[category] = 0;
    });

    periodicData.forEach((item) => {
      entry[item[categoryField]] += item[sumField];
    });

    return entry;
  });

  return chartData;
};

export const filterByCategory = (array, value, categoryField) => {
  const filteredArray = array.filter((obj) => obj[categoryField] === value);

  return filteredArray;
};

export function groupByPeriodRunTotal(
  transactions,
  period,
  dateField,
  sumField,
  categoryField
) {
  // Group transactions by period
  const groupedByPeriod = groupByPeriod(transactions, period, dateField);

  let runningTotals = {};
  let result = [];

  for (const period in groupedByPeriod) {
    let periodTotals = {};

    groupedByPeriod[period].forEach((transaction) => {
      const category = transaction[categoryField];
      const sum = transaction[sumField];
      if (!periodTotals[category]) {
        periodTotals[category] = 0;
      }

      if (!runningTotals[category]) {
        runningTotals[category] = 0;
      }

      periodTotals[category] += sum;
      runningTotals[category] += sum;
    });

    const entry = { name: period };

    for (const account in periodTotals) {
      entry[account] = periodTotals[account];
    }

    for (const account in runningTotals) {
      entry[account] = runningTotals[account];
    }

    result.push(entry);
  }

  return result;
}

export const groupByCategory = (array, sumField, categoryField) => {
  let outcome = {};

  array.forEach((row) => {
    const category = row[categoryField];
    let sum = row[sumField];

    sum = !isNaN(sum) ? sum : 0; // checking if wrong format

    if (outcome[category]) {
      outcome[category] += sum;
    } else {
      outcome[category] = sum;
    }
  });

  // Convert the outcome object into an array of objects with name and value properties
  const resultArray = Object.keys(outcome).map((category) => {
    return { name: category, value: outcome[category] };
  });

  return resultArray;
};
