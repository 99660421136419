import React from "react";
import { useDashboardContext } from "../context";
import WidgetSection from "./dashboard/WidgetsSection";
import ChartsSection from "./dashboard/ChartsSection";
import LoadingMessage from "./LoadingMessage";
import TrasactionsTable from "./dashboard/TransactionsTable";

function Dashboard() {
  const { loading, error } = useDashboardContext();

  if (loading) {
    return <LoadingMessage />;
  }

  if (error) {
    return (
      <div className="message">
        <h2>ERROR: {error}</h2>
      </div>
    );
  }

  return (
    <main>
      <WidgetSection />
      <ChartsSection />
      <TrasactionsTable />
    </main>
  );
}

export default Dashboard;
//
