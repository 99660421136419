import React, { useState, useEffect } from "react";
import { useDashboardContext } from "../../context";
import { groupByPeriodCategory } from "../../utils/dataAnalysis";
import { CustomTooltip } from "./CustomTooltips";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

const StackChart = () => {
  const { stagedSpreadsheet, sourcesColors } = useDashboardContext();
  const [chartData, setChartData] = useState([]);
  const [sources, setSources] = useState([]);

  useEffect(() => {
    if (stagedSpreadsheet) {
      try {
        // grouping original data by month and by source
        const newStackChartData = groupByPeriodCategory(
          stagedSpreadsheet,
          "month",
          "date",
          "sum",
          "source"
        );
        // making the list of all sources names to be used in the chart
        const newSources = [
          ...new Set(stagedSpreadsheet.map((item) => item["source"])),
        ];
        setChartData(newStackChartData);
        setSources(newSources);
      } catch (error) {
        console.log(error);
      }
    }
  }, [stagedSpreadsheet]);

  if (!chartData || !chartData.length) {
    return <div>No data available for chart rendering</div>;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData} stackOffset="sign">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickLine={false} />
        <YAxis tickLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine
          y={0}
          stroke="#de3333"
          strokeWidth={2}
          strokeDasharray="6 6"
        />
        {sources.map((key, index) => (
          <Bar
            key={index}
            dataKey={key}
            stackId="a"
            fill={sourcesColors[key]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackChart;
