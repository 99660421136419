import React, { useContext, useEffect, useReducer } from "react";
import getData from "./utils/dataAPI";
import reducer from "./reducer";

const DashboardContext = React.createContext();

const initialState = {
  spreadsheet: null,
  stagedSpreadsheet: null,
  loading: false,
  error: null,
  accountsColors: {},
  sourcesColors: {},
  selectedAccount: "all",
};

const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // fetching raw data, not mutable spreadsheet
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "LOADING" });
      try {
        const data = await getData(
          process.env.REACT_APP_API_KEY,
          process.env.REACT_APP_SHEET_ID,
          process.env.REACT_APP_SHEET_NAME
        );
        dispatch({ type: "FETCH_SPREADSHEET", payload: data });
      } catch (error) {
        dispatch({ type: "ERROR", payload: error });
      }
    };

    fetchData();
  }, []);

  // copying staging data and colors for further manipulations
  useEffect(() => {
    if (state.spreadsheet) {
      try {
        dispatch({ type: "STAGE_SPREADSHEET" });
        dispatch({ type: "SET_PALETTE" });
      } catch (error) {
        console.error("Error processing spreadsheet:", error);
      }
    }
  }, [state.spreadsheet]);

  const filterByAccount = (account) => {
    dispatch({ type: "FILTER_ACCOUNT", payload: account });
  };

  return (
    <DashboardContext.Provider
      value={{
        ...state,
        filterByAccount,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

//custom hook
const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export { useDashboardContext, DashboardProvider };
