// module with fetching data from Google sheet using Google API
import { formatData } from "./formating";

const getData = async (apiKey, sheetId, sheetName) => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}&valueRenderOption=FORMATTED_VALUE`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const rawData = await response.json();
    const transformedData = transformResponse(rawData);
    const formattedData = formatData(transformedData);

    return formattedData;
  } catch (error) {
    throw new Error("Error fetching data: " + error.message);
  }
};

// transform fetched information from Google API for better format object
const transformResponse = (response) => {
  const keys = response.values[0];
  const data = response.values.slice(1);
  const obj = data.map((arr) =>
    Object.assign({}, ...keys.map((k, i) => ({ [k]: arr[i] })))
  );

  return obj;
};

export default getData;
