import React, { useState, useEffect } from "react";
import { useDashboardContext } from "../../context";
import { groupByPeriodRunTotal } from "../../utils/dataAnalysis";
import { CustomTooltip } from "./CustomTooltips";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function AccountsLineChart() {
  const { stagedSpreadsheet, accountsColors } = useDashboardContext();
  const [chartData, setChartData] = useState([]);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (stagedSpreadsheet) {
      try {
        // grouping original data by month and total sum for each account
        const newLineChartData = groupByPeriodRunTotal(
          stagedSpreadsheet,
          "month",
          "date",
          "sum",
          "account"
        );

        //making list of all possible accounts names - usefull to have asa state value for the dashboard
        const newAccounts = [
          ...new Set(stagedSpreadsheet.map((item) => item["account"])),
        ];
        setChartData(newLineChartData);
        setAccounts(newAccounts);
      } catch (error) {
        console.log(error);
      }
    }
  }, [stagedSpreadsheet]);

  if (!chartData || !chartData.length) {
    return <div>No data available for chart rendering</div>;
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickLine={false} />
        <YAxis tickLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {accounts.map((account, index) => {
          return (
            <Line
              key={index}
              type="monotone"
              dataKey={account}
              stroke={accountsColors[account]}
              strokeWidth={3}
              activeDot={{ r: 8 }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default AccountsLineChart;
