import React, { useState, useEffect } from "react";
import { useDashboardContext } from "../../context";
import { sumByCategory } from "../../utils/dataAnalysis";
import Widget from "./Widget";

function WidgetSection() {
  const { spreadsheet, accountsColors } = useDashboardContext();
  const [accountsTotals, setAccountsTotals] = useState([]);

  useEffect(() => {
    if (spreadsheet) {
      try {
        const newAccountsTotals = sumByCategory(spreadsheet, "sum", "account");
        setAccountsTotals(newAccountsTotals);
      } catch (error) {
        console.log(error);
      }
    }
  }, [spreadsheet]);

  return (
    <section className="widget-section">
      {Object.keys(accountsTotals).map((key) => {
        return (
          <Widget
            account={key}
            color={accountsColors[key]}
            sum={accountsTotals[key]}
            key={key}
          />
        );
      })}
    </section>
  );
}

export default WidgetSection;
