import React, { useState } from "react";
import { useDashboardContext } from "../../context";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

function TrasactionsTable() {
  const { stagedSpreadsheet } = useDashboardContext();
  const [columnDefs] = useState([
    { field: "date", cellDataType: "date", resizable: true, sortable: true },
    { field: "account", cellDataType: "text", resizable: true, sortable: true },
    { field: "source", cellDataType: "text", resizable: true, sortable: true },
    {
      field: "sum",
      cellDataType: "number",
      resizable: true,
      valueFormatter: (params) => {
        const value = params.value;
        const prefix = value < 0 ? "-€" : "€";
        return `${prefix}${Math.abs(value).toFixed(2)}`;
      },
    },
    { field: "comment", cellDataType: "text", resizable: true, width: 350 },
  ]);

  const onFirstDataRendered = (params) => {
    params.columnApi.autoSizeColumns();
  };
  return (
    <div className="charts-section">
      <div className="ag-theme-material" style={{ height: 300, width: "100%" }}>
        <AgGridReact
          rowData={stagedSpreadsheet}
          columnDefs={columnDefs}
          onFirstDataRendered={onFirstDataRendered}
        ></AgGridReact>
      </div>
    </div>
  );
}

export default TrasactionsTable;
