const stringToDate = (_date, _format, _delimiter) => {
  const formatLowerCase = _format.toLowerCase();
  const formatItems = formatLowerCase.split(_delimiter);
  const dateItems = _date.split(_delimiter);
  const monthIndex = formatItems.indexOf("mm");
  const dayIndex = formatItems.indexOf("dd");
  const yearIndex = formatItems.indexOf("yyyy");
  let month = parseInt(dateItems[monthIndex]);
  month -= 1;
  const formatedDate = new Date(
    dateItems[yearIndex],
    month,
    dateItems[dayIndex]
  );
  return formatedDate;
};

// format date and numeric values into proper format
export const formatData = (data) => {
  const formatedData = data.map((row) => {
    const formatedRow = {};

    Object.entries(row).forEach(([key, value]) => {
      if (key.toLowerCase().includes("date")) {
        formatedRow[key] = stringToDate(value, "dd/MM/yyyy", "/");
      } else if (key.toLowerCase() === "sum") {
        formatedRow[key] = parseFloat(value);
      } else {
        formatedRow[key] = String(value);
      }
    });

    return formatedRow;
  });

  return formatedData;
};
