import React from "react";
import StackChart from "./StackChart";
import AccountsLineChart from "./AccountsLineChart";
import PieChartsDiv from "./PieChartsDiv";

function ChartsSection() {
  return (
    <div className="charts-section">
      <div className="chart-area">
        <AccountsLineChart />
      </div>
      <div className="chart-area">
        <StackChart />
      </div>
      <div className="chart-area">
        <PieChartsDiv />
      </div>
    </div>
  );
}

export default ChartsSection;
