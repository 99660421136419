import React from "react";
import logo from "../static/logo.png";

function Header() {
  return (
    <header>
      <img src={logo} className="logo" alt="logo" />
      <div className="title">
        <h3>budget dashboard</h3>
      </div>
    </header>
  );
}

export default Header;
