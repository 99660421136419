import React from "react";
import { FcGoogle } from "react-icons/fc";

function LoginButton({ login }) {
  return (
    <section className="login-section">
      <button onClick={login} className="login-btn">
        <FcGoogle className="logo" />
        <p>Sign in with google</p>
      </button>
    </section>
  );
}

export default LoginButton;
