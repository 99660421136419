import React from "react";
import { useDashboardContext } from "../../context";
import { CustomTooltipSimple } from "./CustomTooltips";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

function SourcesPieChart({ chartData, title }) {
  const { sourcesColors } = useDashboardContext();
  return (
    <div className="pie-chart-area">
      <strong>{title}</strong>
      <ResponsiveContainer width="100%" height={400} minWidth={400}>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius="70%"
            label={({ value }) => {
              return `€${value}`;
            }}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={sourcesColors[entry.name]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltipSimple title={title} />} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SourcesPieChart;
