import React, { useState, useEffect } from "react";
import { useDashboardContext } from "../../context";
import { filterByCategory, groupByCategory } from "../../utils/dataAnalysis";
import SourcesPieChart from "./SourcesPieChart";

function PieChartsDiv() {
  const { stagedSpreadsheet } = useDashboardContext();
  const [revenueData, setRevenueData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);

  useEffect(() => {
    if (stagedSpreadsheet) {
      try {
        const revenue = filterByCategory(stagedSpreadsheet, "debit", "type");
        const expenses = filterByCategory(stagedSpreadsheet, "credit", "type");
        const newRevenueSourcesData = groupByCategory(revenue, "sum", "source");
        const newExpensesSourcesData = groupByCategory(
          expenses,
          "sum",
          "source"
        );
        setRevenueData(newRevenueSourcesData);
        setExpensesData(
          newExpensesSourcesData.map((obj) => {
            const newValue = obj.value * -1;
            return { name: obj.name, value: newValue };
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [stagedSpreadsheet]);

  if (!revenueData || !expensesData) {
    return <div>No data to show Income and Expenses structure </div>;
  }

  return (
    <>
      <SourcesPieChart chartData={revenueData} title="Income" />
      <SourcesPieChart chartData={expensesData} title="Expenses" />
    </>
  );
}

export default PieChartsDiv;
