// Cold colors related or complementary to bright blue
export const coldPalette = [
  "#00BFFF", // Deep Sky Blue
  "#6A5ACD", // Slate Blue
  "#00CED1", // Dark Turquoise
  "#4682B4", // Steel Blue
  "#8EB85E",
  "#97AFBA",
  "#00C3A7",
  "#7FB4E2",
  "#68DFEC",
  "#2E8B57", // Sea Green
];

// Warm colors related or complementary to pink
export const warmPalette = [
  "#FF1493", // Deep Pink
  "#FF6956",
  "#FF4500", // Orange Red
  "#F572E5",
  "#FFA8D6",
  "#DC143C", // Crimson
];

export const mainPalette = ["#46A6F8", "#DC0076"];

export const assignColors = (dataArray, colorsPalette, categoryField) => {
  const assignedColorsObj = {};

  const allCategories = [
    ...new Set(dataArray.map((item) => item[categoryField])),
  ];

  allCategories.forEach((category, index) => {
    assignedColorsObj[category] = colorsPalette[index];
  });

  return assignedColorsObj;
};

export const assignColorsGrouped = (
  dataArray,
  palettesArray,
  groupField,
  categoryField
) => {
  const assignedColorsObj = {};
  const groupedCategories = {};
  const groups = [...new Set(dataArray.map((item) => item[groupField]))];

  groups.forEach((group, index) => {
    const filteredData = dataArray.filter((obj) => obj[groupField] === group);
    const categories = [
      ...new Set(filteredData.map((item) => item[categoryField])),
    ];

    groupedCategories[group] = categories;
  });

  groups.forEach((group, groupIndex) => {
    const colors = palettesArray[groupIndex];
    const categories = groupedCategories[group];

    categories.forEach((category, categIndex) => {
      assignedColorsObj[category] = colors[categIndex];
    });
  });

  return assignedColorsObj;
};
