import React from "react";
import logo from "../static/roboteria.png";

function Footer() {
  return (
    <footer>
      <p>empowered by</p>
      <img src={logo} className="roboteria-logo" alt="roboteria" />
    </footer>
  );
}

export default Footer;
