import { filterByCategory } from "./utils/dataAnalysis";
import {
  assignColors,
  assignColorsGrouped,
  mainPalette,
  coldPalette,
  warmPalette,
} from "./utils/colors";

function reducer(state, action) {
  if (action.type === "LOADING") {
    return { ...state, loading: true };
  }
  if (action.type === "FETCH_SPREADSHEET") {
    return { ...state, loading: false, spreadsheet: action.payload };
  }
  if (action.type === "ERROR") {
    return { ...state, error: action.payload };
  }

  if (action.type === "STAGE_SPREADSHEET") {
    return { ...state, stagedSpreadsheet: state.spreadsheet };
  }

  if (action.type === "SET_PALETTE") {
    const accountsColors = assignColors(
      state.spreadsheet,
      mainPalette,
      "account"
    );
    const sourcesColors = assignColorsGrouped(
      state.spreadsheet,
      [coldPalette, warmPalette],
      "account",
      "source"
    );
    return {
      ...state,
      accountsColors: accountsColors,
      sourcesColors: sourcesColors,
    };
  }

  if (action.type === "FILTER_ACCOUNT") {
    const account = action.payload;

    if (account !== state.selectedAccount) {
      const selectedData = filterByCategory(
        state.spreadsheet,
        account,
        "account"
      );
      return {
        ...state,
        stagedSpreadsheet: selectedData,
        selectedAccount: account,
      };
    } else {
      return {
        ...state,
        stagedSpreadsheet: state.spreadsheet,
        selectedAccount: "all",
      };
    }
  }

  return state;
}

export default reducer;
