import React from "react";

export function CustomTooltip({ payload, label, active }) {
  const filteredPayload = payload.filter((entry) => entry.value !== 0);

  if (active) {
    return filteredPayload.length > 0 ? (
      <div className="custom-tooltip">
        {label && <p style={{ marginBottom: 5 }}>{label}</p>}
        {filteredPayload.map((entry) => (
          <p key={entry.dataKey} style={{ color: entry.color }}>
            {entry.value > 0
              ? `${entry.dataKey}: €${entry.value}`
              : `${entry.dataKey}: -€${entry.value * -1}`}
          </p>
        ))}
      </div>
    ) : null;
  }

  return null;
}

export function CustomTooltipSimple({ payload, active }) {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p style={{ color: payload[0].color }}>
          {`${payload[0].name} : €${payload[0].value}`}
        </p>
      </div>
    );
  }

  return null;
}
