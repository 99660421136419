import React, { useState, useEffect } from "react";
import { useDashboardContext } from "../../context";

function Widget({ account, sum, color }) {
  const { filterByAccount, selectedAccount } = useDashboardContext();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (selectedAccount === account) {
      setIsSelected(true);
    } else setIsSelected(false);
  }, [selectedAccount, account]);

  return (
    <div
      className={`widget ${isSelected ? "selected" : ""}`}
      onClick={() => filterByAccount(account)}
    >
      <span className="widget-title">
        <h4>{account}</h4>
      </span>
      <span className="widget-content">
        <h3 style={{ color: `${color}` }}>€{sum.toFixed(2)}</h3>
      </span>
    </div>
  );
}

export default Widget;
