import React from "react";
import { TbFaceIdError } from "react-icons/tb";

function ErrorMessage() {
  return (
    <section className="error-section">
      <TbFaceIdError className="logo" />
      <h3>You don't have access to this page</h3>
    </section>
  );
}

export default ErrorMessage;
