import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Dashboard from "./components/Dashboard";
import { DashboardProvider } from "./context";
import allowedUsers from "./users";
import LoginButton from "./components/LoginButton";
import ErrorMessage from "./components/errorMessage";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function App() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loginError, setLoginError] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log(error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    if (profile && allowedUsers.includes(profile.email)) {
      setLoginError(false);
    } else {
      setLoginError(true);
    }
  }, [profile]);

  return (
    <>
      {profile ? (
        !loginError ? (
          <>
            <Header />
            <DashboardProvider>
              <Dashboard />
            </DashboardProvider>
            <Footer />
          </>
        ) : (
          <ErrorMessage />
        )
      ) : (
        <>
          <LoginButton login={login} />
        </>
      )}
    </>
  );
}

export default App;
//
